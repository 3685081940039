import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';


function Contact() {

	const form = useRef();

	const [Email, setEmail] = useState("");
	const [Name, setName] = useState("");
	const [Description, setDescription ] = useState("");
	const [Agree, setChecked] = useState(false);

	const onNameHandler = (event) => {
		setName(event.currentTarget.value);
	}

	const onEmailHandler = (event) => {
		setEmail(event.currentTarget.value);
	}

	const onDescriptionHandler = (event) => {
		setDescription(event.currentTarget.value);
	}

	const onAgreeHandler = (e) => {
		setChecked(!Agree);		
	}

	const sendEmail = (e) => {
		e.preventDefault();

		if(Name === "") {
			window.alert("이름을 입력해주세요");
			return;
		}

		if(Email === "") {
			window.alert("이메일을 입력해주세요");
			return;
		}

		if(Description === "") {
			window.alert("문의사항을 입력해주세요");
			return;
		}

		// 개인정보제공 동의 확인
		if(Agree===false) {
			window.alert("개인정보 제공 동의가 필요합니다.");
			return;
		}

		emailjs.sendForm('service_zqb97tu', 'template_b5ap3fk', e.target , 'AECCueJ9pnlWQE1qh')
			.then((result) => {
				console.log(result.text);
				setEmail("");
				setChecked(false);
				setName("");
				setDescription("");
				window.alert("문의가 등록되었습니다. 감사합니다.");
			}, (error) => {
				console.log(error.text);
			});
	};

	return (
		<div id="contact" className="contact">
		<div className="wrapper">
			<div className="contactform">
				<h1 className="co_title">CONTACT</h1>
				<form name="request" id="request" ref={form} onSubmit={sendEmail}>
					<label>NAME</label>
					<input type="text" id="name" name="name" placeholder="ENTER YOUR NAME" value={Name} onChange={onNameHandler} />
					<label>EMAIL</label>
					<input type="text" id="email" name="email" placeholder="ENTER YOUR EMAIL" value={Email} onChange={onEmailHandler} />
					<label>DESCRIPTION</label>
					<textarea id="description" name="description" value={Description} onChange={onDescriptionHandler} ></textarea>
					<div className="personalInfo">
						<label><input type="checkbox" name="agree" id="agree" checked={Agree} onChange={onAgreeHandler} /> I agree to the <span>collection and use of my personal information</span></label>
					</div>
					<input type="submit" value="SEND" />
				</form>
			</div>
			<div className="info">
				<span>ADDRESS</span>
				<p>
					12, Digital-ro 31-gil, Guro-gu, Seoul, Republic of Korea
				</p>
				<span>EMAIL</span>
				<p>
					YISI@YISISTORY.COM
				</p>
			</div>
		</div>
	</div>
	)
}

export default Contact;