import React from 'react';

function MobileMenu() {
	return (
		<div className="mobileMenu">
			<div className="mWrapper">
				<div><a href="#about">ABOUT</a></div>
				<div></div>
				<div><a href="#portfolio">PORTFOLIO</a></div>
				<div></div>
				<div><a href="#contact">CONTACT</a></div>
			</div>
		</div>
	)
}

export default MobileMenu;