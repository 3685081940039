import Header from "./components/Header";
import Visual from "./components/Visual";
import About from "./components/About"
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import MobileMenu from "./components/MobileMenu";

function App() {
	return (
		<div className="container">
			<Header />
			<Visual />
			<About />
			<Portfolio />
			<Contact />
			<Footer />
			<MobileMenu />
		</div>		
	);
}

export default App;