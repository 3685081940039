import React from 'react';

function Header() {
	return (
		<div className='header'>
			<div className="gnb">
				<div className="logo"><img src="img/CI.png" srcSet="/img/CI@2x.png 2x, /img/CI@3x.png 3x" alt="CI" /></div>
				<nav id="menu">
					<ul>
						<li><a href="#about">ABOUT</a></li>
						<li>|</li>
						<li><a href="#portfolio">PORTFOLIO</a></li>
						<li>|</li>
						<li><a href="#contact">CONTACT</a></li>
					</ul>
				</nav>			
			</div>
		</div>
	);
}

export default Header;