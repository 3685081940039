import React from 'react';

function Portfolio() {
	return (
		<div id="portfolio" className="portfolio">
		<div className="wrapper">
			<div className="port1">
				<a href='https://giroc.io' target='_blank'><img src="img/Giroc.png" alt="Giroc" /></a>
				<p>
					요청사항 관리 ITSM '기록'
				</p>
				<p>
					기획, 디자인, Back-end, Front-end <br />
					요청사항 관리 (ITSM) 시스템으로 무제한 고객 및 멤버 추가, 100% 무료<br />
					React, Typescript, Redux, Java, Spring, Spring Security, MariaDB
				</p>
			</div>
			<div className="port2">
				<img src="img/un.png" alt="UNHCR" />
				<p>
					UN 난민기구 디지털 사인업 시스템
				</p>
				<p>
					후원 약정서 플랫폼 및 약정서 관리사이트 구축 및 운영/유지보수<br />
					종이 약정서를 디지털 방식으로 전환하는 프로젝트로 후원 및 후원자 정보를 GLOUD에 보관하고 관리하는 사이트를 구축<br />
					서류 누락 방지, 종이 사용감소, 휴먼에러 감소 효과를 거둠
				</p>
			</div>
			<div className="lineimg">
				<p></p>
				<img src="img/ajamoon.png" alt="line" />
				<p></p>
			</div>
			<div className="listnoti">
				<ul>
					<li>AgainJob (경력단절된 사람 전용 일거리/취업 매칭) - 진행 중</li>
					<li>기록 (요청사상 관리 ITSM)</li>
					<li>법무법인 화우 IR사이트 리뉴얼</li>
					<li>여행과지도 (렌터카 예약 시스템)</li>
					<li>UN난민기구 디지털사인업(온라인 후원약정 시스템)</li>
					<li>김천관광공사 키오스크 관광안내 웹</li>
					<li>태양열 발전소 모니터링 시스템</li>
					<li>한국투자증권 로보리서치 관리자</li>
					<li>사회적가치연구원 뉴스룸(ESG)</li>
					<li>동영상/음원 플랫폼</li>
					<li>삼성 Tizen Store</li>
					<li>세이브더칠드런</li>
					<li>세이브더칠드런 (경영업무 지원, 외부후원 등록, 상담, 채용 시스템)</li>
					<li>롯데 칠성몰</li>
					<li>하나 SK카드 모바일</li>
				</ul>
				<div className="noti">
					* 상기 목록은 개인 자격으로 참여한 프로젝트를 포함했습니다.
				</div>
			</div>
		</div>
	</div>
	);
}

export default Portfolio;