import React from 'react';

function Visual() {
	return (
		<div className="mainVisual">
			<div className="visualtext">EXPERT GROUP FOR NEXT TIME</div>
		</div>
	)
}

export default Visual;