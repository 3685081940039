import React from 'react';

function About() {
	return (
		<div id="about" className="about">
			<div className="aboutContent">
				<div id="slogan" className="slogan">
					<p>
						이시는<br />다음 시간을<br />준비하고<br />있습니다.
					</p>
					<p className="desc">
						이시는 IT경력 20년차 이상으로 구성된 전문가 그룹으로 Project Consulting부터 최종 Prouct까지 Total 서비스를 지향하며, <br />
						모니터링 시스템부터 글로벌 스토어, 동영상/음원 서비스와 최근 디지털 사인업, 키오스크 서비스 등 다양한 업무를 성공적으로 수행해 왔습니다. <br />
						또한, 고객과의 원활한 커뮤니케이션 및 업무 관리를 위해 <b>YISI's service portal system</b>을 도입하여 업무 진행을 최적화하고 불필요한 비용을 최소화 했습니다.
					</p>
				</div>
				<ul>
					<li className="sTitle">PROJECT MANAGEMENT</li>
					<li className="sDesc"> - PMP, PRINCE2</li>
					<li className="sTitle">STRATEGIC ARCHTECT</li>
					<li className="sDesc"> - MEGA PROCESS, POLICY, FUNCTION DESIGN</li>
					<li className="sTitle">STRONG DEVELOPMENT</li>
					<li className="sDesc"> - INFRA, CLOUD, LANGUAGE, API, FRAMEWORK</li>
					<li className="sTitle">PROJECT METHODOLOGY</li>
					<li className="sDesc"> - WATERFALL, AGILE, DEVOPS</li>
				</ul>
				<p>
					PROJECT MANAGEMENT <span>(PMP, PRINCE2)</span>
				</p>
				<p>
					ARCHTECT <span>(MEGA PROCESS, POLICY, FUNCTION DESIGN)</span>
				</p>
				<p>
					STRONG DEVELOPMENT<br /><span>(INFRA, CLOUD, LANGUAGE, API, FRAMEWORK)</span>
				</p>
				<p>
					PROJECT METHODOLOGY <span>(WATERFALL, AGILE, DEVOPS)</span>
				</p>
			</div>
		</div>
	);
}

export default About;