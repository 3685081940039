import React from 'react';

function Footer() {
	return (
		<div className="footer">
			<div className="copyright">
				<p>
					203-55-67463 | YISI@YISISTORY.COM
				</p>
				<p>
					COPYRIGHT ⓒ 2021 YISI ALL RIGHTS RESERVED.
				</p>
			</div>
		</div>
	)
}

export default Footer;